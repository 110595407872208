@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      @apply font-mono;
    }

    :root {
        --scrollbarTrack: hsla(var(--b1));
        --scrollbarThumb: hsla(var(--n));
        --scrollbarThumbHover: hsla(var(--p));
    }
    
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: var(--rounded-box, 1rem);
    }
      
          
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--scrollbarTrack);
        border-radius: var(--rounded-box, 1rem);
    }
        
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--scrollbarThumb);
        border-radius: var(--rounded-box, 1rem);
    }
        
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbarThumbHover);
    }
    
    ::-webkit-scrollbar:hover ::-webkit-scrollbar-thumb{      
        background: var(--scrollbarThumbHover);
    }

    @screen sm {
        ::-webkit-scrollbar {
            width: 12px;
        }
    }
    
    .container {
        @apply px-2 md:px-8
    }
}

@layer components {
    .menu-button{
        @apply bg-transparent;
    }
    .menu-button:hover, .menu-button:focus, .menu-button:active, .menu-button.active {
        @apply bg-transparent text-accent;
    }     

    .mockup-code{
      @apply relative cursor-pointer pb-0
    }

    .mockup-code .prompt{
      width: calc(100% - 32px);
    }

    .mockup-code.maximized {
      @apply z-20 fixed cursor-default left-4 right-4 sm:left-16 sm:right-16 top-16 bottom-16;
    }

    .mockup-code.maximized figure{
      @apply h-half overflow-hidden  absolute
    }

    .mockup-code.maximized figure img{
      @apply w-full
    }

    .mockup-code:not(.maximized) .info{
      @apply absolute bottom-2 px-2 flex flex-1 justify-end items-center w-full flex-wrap
    }

    .mockup-code.maximized .info{
      @apply lg:absolute lg:bottom-2 px-2 flex flex-1 flex-col lg:flex-row gap-4 justify-center lg:justify-end  items-center w-full flex-wrap
    }

    .mockup-code .tags{
      @apply justify-center lg:justify-start flex-wrap
    }

    .mockup-code .card {
      @apply relative;
    }

    .mockup-code .card-body{
      margin-top: 50vh;
    }

    .mockup-code.maximized .info{
      top: calc(50vh - 2.5rem);
      bottom: auto;
    }

    @media (max-width: theme('screens.sm')) {        
        .menu {
            @apply fixed top-0 left-0 bg-base-100 w-full h-screen justify-center p-16 transition-opacity duration-500 ease-in-out translate-y-full opacity-0;
        }

        .menu.open{
            @apply translate-y-0 opacity-100;
        }

        .menu-button:before {
            content: '> '
        }

        .navbar .dropdown-content {
            @apply top-12 left-0 w-full
        }
    }

    .mockup-code label {
      position: absolute;
      top: 12px;
      left: 6rem;
      width: calc(100% - 112px);
      background-color: white;
    }

    .mockup-code.maximized label {      
      width: calc(100% - 140px);
    }
}

@layer utilities {
    .writing-mode-vertical {      
        writing-mode: vertical-rl;
    }

    .glitch:before {
        content: attr(data-text);
        position: absolute;
        left: -1px;
        text-shadow: 2px 0 hsla(var(--p));
        top: 1px;
        color: inherit;
        background: hsla(var(--b1) / var(--tw-bg-opacity, 1));
        overflow: hidden;
        clip: rect(0, 900px, 0, 0);
        animation: glitch 2s infinite linear alternate-reverse;
    }

    .prose.about p, .prose.about li{
      @apply m-0 leading-6
    }

    .prose.about h2{
      @apply m-0 py-2
    }

    @keyframes glitch {
        0% {
          clip: rect(44px, 9999px, 44px, 0);
        }
        5% {
          clip: rect(44px, 9999px, 93px, 0);
        }
        10% {
          clip: rect(60px, 9999px, 29px, 0);
        }
        15% {
          clip: rect(53px, 9999px, 89px, 0);
        }
        20% {
          clip: rect(77px, 9999px, 36px, 0);
        }
        25% {
          clip: rect(7px, 9999px, 63px, 0);
        }
        30% {
          clip: rect(45px, 9999px, 79px, 0);
        }
        35% {
          clip: rect(99px, 9999px, 69px, 0);
        }
        40% {
          clip: rect(13px, 9999px, 81px, 0);
        }
        45% {
          clip: rect(54px, 9999px, 62px, 0);
        }
        50% {
          clip: rect(14px, 9999px, 11px, 0);
        }
        55% {
          clip: rect(65px, 9999px, 95px, 0);
        }
        60% {
          clip: rect(13px, 9999px, 11px, 0);
        }
        65% {
          clip: rect(43px, 9999px, 49px, 0);
        }
        70% {
          clip: rect(47px, 9999px, 60px, 0);
        }
        75% {
          clip: rect(53px, 9999px, 54px, 0);
        }
        80% {
          clip: rect(22px, 9999px, 62px, 0);
        }
        85% {
          clip: rect(59px, 9999px, 23px, 0);
        }
        90% {
          clip: rect(4px, 9999px, 55px, 0);
        }
        95% {
          clip: rect(47px, 9999px, 20px, 0);
        }
        100% {
          clip: rect(13px, 9999px, 7px, 0);
        }
      }
      

    @supports (-webkit-text-stroke: 1px hsla(var(--s))) {
        .text-outline  {
          -webkit-text-stroke: 1px hsla(var(--s));
          color: transparent;
        }
    }
}